@mixin transition($variable, $time: 0.35s, $easing: ease)
  transition: $variable $time $easing
  -moz-transition: $variable $time $easing
  -webkit-transition: $variable $time $easing


$light-neutral-color: #e4e4e4
$primary-color: #0096ff
$danger-color: #f75959
$main-orange-color: #EDB364
$gradient-orange-color: #E9856D
$main-solid-green: #0D9DA4
@mixin shadow
  border: 1px solid $grey-light
  box-shadow: 0 4px 6px -5px rgba(107, 121, 153, 0.35)

.is-main-orange
  color: $main-orange-color
.is-gradient-orange
  color: $gradient-orange-color
.is-main-solid-green
  color: $main-solid-green

.turbolinks-progress-bar
  height: 4px
  background-color: $contrast

::selection
  background: $primary
  color: white

.hide, .hidden
  display: none

a
  @include transition(color)

a[data-read-more-trigger]
  color: $black
  text-decoration: underline
  font-weight: 400

  &:hover
    text-decoration: none

ul
  margin-bottom: 1rem

p
  &.highlight
    text-transform: uppercase
    font-size: 14px
    font-weight: 400

  &.disclaimer
    font-size: 10px

.center
  text-align: center

.red
  color: $danger !important

/* bulma overrides */
.heading
  font-size: 1em
  letter-spacing: initial
  margin-bottom: 0px
  text-transform: none

html
  height: 100%
  -webkit-font-smoothing: antialiased
  text-rendering: optimizeLegibility


body
  background-color: #F3F8FB

body.practitioner_searches,
body.practitioner_searches.has-navbar-fixed-top,
body.portal,
body.portal.has-navbar-fixed-top,
body.practitioners,
body.practitioners.has-navbar-fixed-top,
body.custom_domain
  background-color: #fff

footer
  border-top: 1px solid #E4EFF6

.healme-widget
  position: absolute